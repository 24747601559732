<template>
  <div>
    <el-card class="card" v-loading="loading">
      <van-cell-group class="cellgroup">
        <van-cell title="序列号" :value="serialNumber" />
        <van-cell title="温度返回时间" :value="wxcw01?wxcw01.dataTime:'-'" />
        <van-cell title="T1" :value="(wxcw01&&wxcw01.t1!=2500)?wxcw01.t1/10.0+'℃':'-'" />
        <van-cell title="T2" :value="(wxcw01&&wxcw01.t2!=2500)?wxcw01.t2/10.0+'℃':'-'" />
        <van-cell title="T3" :value="(wxcw01&&wxcw01.t3!=2500)?wxcw01.t3/10.0+'℃':'-'" />
        <van-cell title="T4" :value="(wxcw01&&wxcw01.t4!=2500)?wxcw01.t4/10.0+'℃':'-'" />
        <van-cell title="T5" :value="(wxcw01&&wxcw01.t5!=2500)?wxcw01.t5/10.0+'℃':'-'" />
        <van-cell title="T6" :value="(wxcw01&&wxcw01.t6!=2500)?wxcw01.t6/10.0+'℃':'-'" />
        <van-cell title="T7" :value="(wxcw01&&wxcw01.t7!=2500)?wxcw01.t7/10.0+'℃':'-'" />
        <van-cell title="T8" :value="(wxcw01&&wxcw01.t8!=2500)?wxcw01.t8/10.0+'℃':'-'" />
        <van-cell title="电量">
          <el-progress :text-inside="true" :stroke-width="23" :percentage="wxcw01?wxcw01.dianLiang:0" :status="wxcw01&&wxcw01.dianLiang>20?'success':'exception'"></el-progress>
        </van-cell>
      </van-cell-group>
    </el-card>
    <van-button class="xuanfu" type="primary" round icon="replay" @click="getLastTemperature"></van-button>
    <van-button class="xuanfu2" type="warning" round icon="eye-o" :to="'/WapWXCW01View/'+serialNumber"></van-button>
    <van-button class="xuanfu1" type="info" round icon="setting-o" :to="'/WapWXCW01Setting/'+serialNumber"></van-button>
  </div>
</template>


<script>
import Vue from "vue";
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);
export default {
  props: ["serialNumber"],
  data() {
    return {
      wxcw01: null,
      loading: false,
    };
  },
  methods: {
    getLastTemperature() {
      let that = this;
      that.loading = true;
      that.axios
        .post("WXCW01/GetLastTemperature", {
          serialNumber: that.serialNumber,
        })
        .then(function (response) {
          that.loading = false;
          that.wxcw01 = response.data.data;
        });
    },
  },
  mounted() {
    let that = this;
    document.title = "无线测温仪";
    that.getLastTemperature();
  },
};
</script>

<style scoped>
.card {
  background-color: #409eff;
}
.xuanfu {
  position: absolute;
  bottom: 20px;
  left: 15px;
}
.xuanfu1 {
  position: absolute;
  bottom: 20px;
  right: 15px;
}
.xuanfu2 {
  position: absolute;
  bottom: 20px;
  left: 45%;
}


</style>